// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Banner-module--cds--assistive-text--9f5a7";
export var cdsLayoutConstraintDensity__defaultCondensed = "Banner-module--cds--layout-constraint--density__default-condensed--1c5d8";
export var cdsLayoutConstraintDensity__defaultNormal = "Banner-module--cds--layout-constraint--density__default-normal--8bd52";
export var cdsLayoutConstraintDensity__maxCondensed = "Banner-module--cds--layout-constraint--density__max-condensed--fedb5";
export var cdsLayoutConstraintDensity__maxNormal = "Banner-module--cds--layout-constraint--density__max-normal--a6d8c";
export var cdsLayoutConstraintDensity__minCondensed = "Banner-module--cds--layout-constraint--density__min-condensed--7b74e";
export var cdsLayoutConstraintDensity__minNormal = "Banner-module--cds--layout-constraint--density__min-normal--c202b";
export var cdsLayoutConstraintSize__default2xl = "Banner-module--cds--layout-constraint--size__default-2xl--c93c7";
export var cdsLayoutConstraintSize__defaultLg = "Banner-module--cds--layout-constraint--size__default-lg--ee36f";
export var cdsLayoutConstraintSize__defaultMd = "Banner-module--cds--layout-constraint--size__default-md--71216";
export var cdsLayoutConstraintSize__defaultSm = "Banner-module--cds--layout-constraint--size__default-sm--68205";
export var cdsLayoutConstraintSize__defaultXl = "Banner-module--cds--layout-constraint--size__default-xl--05e65";
export var cdsLayoutConstraintSize__defaultXs = "Banner-module--cds--layout-constraint--size__default-xs--85994";
export var cdsLayoutConstraintSize__max2xl = "Banner-module--cds--layout-constraint--size__max-2xl--ccf95";
export var cdsLayoutConstraintSize__maxLg = "Banner-module--cds--layout-constraint--size__max-lg--57726";
export var cdsLayoutConstraintSize__maxMd = "Banner-module--cds--layout-constraint--size__max-md--264f3";
export var cdsLayoutConstraintSize__maxSm = "Banner-module--cds--layout-constraint--size__max-sm--d2525";
export var cdsLayoutConstraintSize__maxXl = "Banner-module--cds--layout-constraint--size__max-xl--cf0d9";
export var cdsLayoutConstraintSize__maxXs = "Banner-module--cds--layout-constraint--size__max-xs--b242c";
export var cdsLayoutConstraintSize__min2xl = "Banner-module--cds--layout-constraint--size__min-2xl--4177d";
export var cdsLayoutConstraintSize__minLg = "Banner-module--cds--layout-constraint--size__min-lg--8faa1";
export var cdsLayoutConstraintSize__minMd = "Banner-module--cds--layout-constraint--size__min-md--6a3e0";
export var cdsLayoutConstraintSize__minSm = "Banner-module--cds--layout-constraint--size__min-sm--ebf60";
export var cdsLayoutConstraintSize__minXl = "Banner-module--cds--layout-constraint--size__min-xl--06599";
export var cdsLayoutConstraintSize__minXs = "Banner-module--cds--layout-constraint--size__min-xs--da04f";
export var cdsLayoutDensityCondensed = "Banner-module--cds--layout--density-condensed--c89f8";
export var cdsLayoutDensityNormal = "Banner-module--cds--layout--density-normal--0e5ad";
export var cdsLayoutSize2xl = "Banner-module--cds--layout--size-2xl--18764";
export var cdsLayoutSizeLg = "Banner-module--cds--layout--size-lg--58f71";
export var cdsLayoutSizeMd = "Banner-module--cds--layout--size-md--7633b";
export var cdsLayoutSizeSm = "Banner-module--cds--layout--size-sm--68adf";
export var cdsLayoutSizeXl = "Banner-module--cds--layout--size-xl--9b81c";
export var cdsLayoutSizeXs = "Banner-module--cds--layout--size-xs--d5a55";
export var cdsVisuallyHidden = "Banner-module--cds--visually-hidden--ada63";
export var column = "Banner-module--column--8b8fb";
export var grid = "Banner-module--grid--ef96a";
export var hideFeedback = "Banner-module--hide-feedback--9ddfa";
export var row = "Banner-module--row--fe880";
export var showFeedback = "Banner-module--show-feedback--d586b";
export var skeleton = "Banner-module--skeleton--2120b";