// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Footer-module--cds--assistive-text--b0f19";
export var cdsLayoutConstraintDensity__defaultCondensed = "Footer-module--cds--layout-constraint--density__default-condensed--97031";
export var cdsLayoutConstraintDensity__defaultNormal = "Footer-module--cds--layout-constraint--density__default-normal--72b6e";
export var cdsLayoutConstraintDensity__maxCondensed = "Footer-module--cds--layout-constraint--density__max-condensed--259f8";
export var cdsLayoutConstraintDensity__maxNormal = "Footer-module--cds--layout-constraint--density__max-normal--c47a5";
export var cdsLayoutConstraintDensity__minCondensed = "Footer-module--cds--layout-constraint--density__min-condensed--7822d";
export var cdsLayoutConstraintDensity__minNormal = "Footer-module--cds--layout-constraint--density__min-normal--a0c6f";
export var cdsLayoutConstraintSize__default2xl = "Footer-module--cds--layout-constraint--size__default-2xl--c0df6";
export var cdsLayoutConstraintSize__defaultLg = "Footer-module--cds--layout-constraint--size__default-lg--70775";
export var cdsLayoutConstraintSize__defaultMd = "Footer-module--cds--layout-constraint--size__default-md--7e8d3";
export var cdsLayoutConstraintSize__defaultSm = "Footer-module--cds--layout-constraint--size__default-sm--1ec2f";
export var cdsLayoutConstraintSize__defaultXl = "Footer-module--cds--layout-constraint--size__default-xl--5ffb3";
export var cdsLayoutConstraintSize__defaultXs = "Footer-module--cds--layout-constraint--size__default-xs--40aae";
export var cdsLayoutConstraintSize__max2xl = "Footer-module--cds--layout-constraint--size__max-2xl--0e90f";
export var cdsLayoutConstraintSize__maxLg = "Footer-module--cds--layout-constraint--size__max-lg--09985";
export var cdsLayoutConstraintSize__maxMd = "Footer-module--cds--layout-constraint--size__max-md--7f867";
export var cdsLayoutConstraintSize__maxSm = "Footer-module--cds--layout-constraint--size__max-sm--56d7b";
export var cdsLayoutConstraintSize__maxXl = "Footer-module--cds--layout-constraint--size__max-xl--a95b0";
export var cdsLayoutConstraintSize__maxXs = "Footer-module--cds--layout-constraint--size__max-xs--c586c";
export var cdsLayoutConstraintSize__min2xl = "Footer-module--cds--layout-constraint--size__min-2xl--54f90";
export var cdsLayoutConstraintSize__minLg = "Footer-module--cds--layout-constraint--size__min-lg--c338e";
export var cdsLayoutConstraintSize__minMd = "Footer-module--cds--layout-constraint--size__min-md--27579";
export var cdsLayoutConstraintSize__minSm = "Footer-module--cds--layout-constraint--size__min-sm--58d9a";
export var cdsLayoutConstraintSize__minXl = "Footer-module--cds--layout-constraint--size__min-xl--e9649";
export var cdsLayoutConstraintSize__minXs = "Footer-module--cds--layout-constraint--size__min-xs--500e6";
export var cdsLayoutDensityCondensed = "Footer-module--cds--layout--density-condensed--64276";
export var cdsLayoutDensityNormal = "Footer-module--cds--layout--density-normal--77300";
export var cdsLayoutSize2xl = "Footer-module--cds--layout--size-2xl--8d3f6";
export var cdsLayoutSizeLg = "Footer-module--cds--layout--size-lg--56f18";
export var cdsLayoutSizeMd = "Footer-module--cds--layout--size-md--4b1d8";
export var cdsLayoutSizeSm = "Footer-module--cds--layout--size-sm--e931e";
export var cdsLayoutSizeXl = "Footer-module--cds--layout--size-xl--cc59c";
export var cdsLayoutSizeXs = "Footer-module--cds--layout--size-xs--2c998";
export var cdsVisuallyHidden = "Footer-module--cds--visually-hidden--79839";
export var content = "Footer-module--content--e6561";
export var footer = "Footer-module--footer--676fe";
export var grid = "Footer-module--grid--ec6b8";
export var hideFeedback = "Footer-module--hide-feedback--1494e";
export var listItem = "Footer-module--listItem--06d5b";
export var logo = "Footer-module--logo--92ee4";
export var nav = "Footer-module--nav--3d17a";
export var showFeedback = "Footer-module--show-feedback--6f6f4";
export var skeleton = "Footer-module--skeleton--826e0";