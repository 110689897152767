// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Switcher-module--cds--assistive-text--56cef";
export var cdsLayoutConstraintDensity__defaultCondensed = "Switcher-module--cds--layout-constraint--density__default-condensed--8f342";
export var cdsLayoutConstraintDensity__defaultNormal = "Switcher-module--cds--layout-constraint--density__default-normal--da643";
export var cdsLayoutConstraintDensity__maxCondensed = "Switcher-module--cds--layout-constraint--density__max-condensed--7aa05";
export var cdsLayoutConstraintDensity__maxNormal = "Switcher-module--cds--layout-constraint--density__max-normal--c2cac";
export var cdsLayoutConstraintDensity__minCondensed = "Switcher-module--cds--layout-constraint--density__min-condensed--d987e";
export var cdsLayoutConstraintDensity__minNormal = "Switcher-module--cds--layout-constraint--density__min-normal--18a16";
export var cdsLayoutConstraintSize__default2xl = "Switcher-module--cds--layout-constraint--size__default-2xl--75196";
export var cdsLayoutConstraintSize__defaultLg = "Switcher-module--cds--layout-constraint--size__default-lg--beb92";
export var cdsLayoutConstraintSize__defaultMd = "Switcher-module--cds--layout-constraint--size__default-md--e90f7";
export var cdsLayoutConstraintSize__defaultSm = "Switcher-module--cds--layout-constraint--size__default-sm--dfdf7";
export var cdsLayoutConstraintSize__defaultXl = "Switcher-module--cds--layout-constraint--size__default-xl--22634";
export var cdsLayoutConstraintSize__defaultXs = "Switcher-module--cds--layout-constraint--size__default-xs--c3e66";
export var cdsLayoutConstraintSize__max2xl = "Switcher-module--cds--layout-constraint--size__max-2xl--eedf1";
export var cdsLayoutConstraintSize__maxLg = "Switcher-module--cds--layout-constraint--size__max-lg--8f3d7";
export var cdsLayoutConstraintSize__maxMd = "Switcher-module--cds--layout-constraint--size__max-md--1de33";
export var cdsLayoutConstraintSize__maxSm = "Switcher-module--cds--layout-constraint--size__max-sm--85cb3";
export var cdsLayoutConstraintSize__maxXl = "Switcher-module--cds--layout-constraint--size__max-xl--22fe0";
export var cdsLayoutConstraintSize__maxXs = "Switcher-module--cds--layout-constraint--size__max-xs--cf8a8";
export var cdsLayoutConstraintSize__min2xl = "Switcher-module--cds--layout-constraint--size__min-2xl--1216f";
export var cdsLayoutConstraintSize__minLg = "Switcher-module--cds--layout-constraint--size__min-lg--7c73d";
export var cdsLayoutConstraintSize__minMd = "Switcher-module--cds--layout-constraint--size__min-md--3177d";
export var cdsLayoutConstraintSize__minSm = "Switcher-module--cds--layout-constraint--size__min-sm--51a18";
export var cdsLayoutConstraintSize__minXl = "Switcher-module--cds--layout-constraint--size__min-xl--08cbe";
export var cdsLayoutConstraintSize__minXs = "Switcher-module--cds--layout-constraint--size__min-xs--f1621";
export var cdsLayoutDensityCondensed = "Switcher-module--cds--layout--density-condensed--7479a";
export var cdsLayoutDensityNormal = "Switcher-module--cds--layout--density-normal--2c86f";
export var cdsLayoutSize2xl = "Switcher-module--cds--layout--size-2xl--90cc5";
export var cdsLayoutSizeLg = "Switcher-module--cds--layout--size-lg--6e38f";
export var cdsLayoutSizeMd = "Switcher-module--cds--layout--size-md--946ca";
export var cdsLayoutSizeSm = "Switcher-module--cds--layout--size-sm--9482c";
export var cdsLayoutSizeXl = "Switcher-module--cds--layout--size-xl--f7c29";
export var cdsLayoutSizeXs = "Switcher-module--cds--layout--size-xs--ebb1e";
export var cdsVisuallyHidden = "Switcher-module--cds--visually-hidden--72048";
export var divider = "Switcher-module--divider--7e12e";
export var hideFeedback = "Switcher-module--hide-feedback--6348f";
export var link = "Switcher-module--link--67901";
export var linkDisabled = "Switcher-module--link--disabled--6572e";
export var nav = "Switcher-module--nav--24bef";
export var open = "Switcher-module--open--7c4b1";
export var showFeedback = "Switcher-module--show-feedback--ddda1";
export var skeleton = "Switcher-module--skeleton--6251e";