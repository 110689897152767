// extracted by mini-css-extract-plugin
export var aside = "Aside-module--aside--0a77b";
export var asideNoRule = "Aside-module--asideNoRule--070a8";
export var cdsAssistiveText = "Aside-module--cds--assistive-text--5a066";
export var cdsLayoutConstraintDensity__defaultCondensed = "Aside-module--cds--layout-constraint--density__default-condensed--791b6";
export var cdsLayoutConstraintDensity__defaultNormal = "Aside-module--cds--layout-constraint--density__default-normal--7eb2e";
export var cdsLayoutConstraintDensity__maxCondensed = "Aside-module--cds--layout-constraint--density__max-condensed--03ec5";
export var cdsLayoutConstraintDensity__maxNormal = "Aside-module--cds--layout-constraint--density__max-normal--b0828";
export var cdsLayoutConstraintDensity__minCondensed = "Aside-module--cds--layout-constraint--density__min-condensed--982da";
export var cdsLayoutConstraintDensity__minNormal = "Aside-module--cds--layout-constraint--density__min-normal--8b4f9";
export var cdsLayoutConstraintSize__default2xl = "Aside-module--cds--layout-constraint--size__default-2xl--99b0e";
export var cdsLayoutConstraintSize__defaultLg = "Aside-module--cds--layout-constraint--size__default-lg--3687c";
export var cdsLayoutConstraintSize__defaultMd = "Aside-module--cds--layout-constraint--size__default-md--e2075";
export var cdsLayoutConstraintSize__defaultSm = "Aside-module--cds--layout-constraint--size__default-sm--f6748";
export var cdsLayoutConstraintSize__defaultXl = "Aside-module--cds--layout-constraint--size__default-xl--4281c";
export var cdsLayoutConstraintSize__defaultXs = "Aside-module--cds--layout-constraint--size__default-xs--c9b08";
export var cdsLayoutConstraintSize__max2xl = "Aside-module--cds--layout-constraint--size__max-2xl--60ba1";
export var cdsLayoutConstraintSize__maxLg = "Aside-module--cds--layout-constraint--size__max-lg--639fc";
export var cdsLayoutConstraintSize__maxMd = "Aside-module--cds--layout-constraint--size__max-md--d7f49";
export var cdsLayoutConstraintSize__maxSm = "Aside-module--cds--layout-constraint--size__max-sm--cfdb5";
export var cdsLayoutConstraintSize__maxXl = "Aside-module--cds--layout-constraint--size__max-xl--6fcb7";
export var cdsLayoutConstraintSize__maxXs = "Aside-module--cds--layout-constraint--size__max-xs--7421e";
export var cdsLayoutConstraintSize__min2xl = "Aside-module--cds--layout-constraint--size__min-2xl--43a63";
export var cdsLayoutConstraintSize__minLg = "Aside-module--cds--layout-constraint--size__min-lg--78905";
export var cdsLayoutConstraintSize__minMd = "Aside-module--cds--layout-constraint--size__min-md--8b453";
export var cdsLayoutConstraintSize__minSm = "Aside-module--cds--layout-constraint--size__min-sm--b3da3";
export var cdsLayoutConstraintSize__minXl = "Aside-module--cds--layout-constraint--size__min-xl--ec40e";
export var cdsLayoutConstraintSize__minXs = "Aside-module--cds--layout-constraint--size__min-xs--41dc5";
export var cdsLayoutDensityCondensed = "Aside-module--cds--layout--density-condensed--b25c6";
export var cdsLayoutDensityNormal = "Aside-module--cds--layout--density-normal--b0a73";
export var cdsLayoutSize2xl = "Aside-module--cds--layout--size-2xl--6577b";
export var cdsLayoutSizeLg = "Aside-module--cds--layout--size-lg--0ae0a";
export var cdsLayoutSizeMd = "Aside-module--cds--layout--size-md--c6524";
export var cdsLayoutSizeSm = "Aside-module--cds--layout--size-sm--e6aa0";
export var cdsLayoutSizeXl = "Aside-module--cds--layout--size-xl--8ce05";
export var cdsLayoutSizeXs = "Aside-module--cds--layout--size-xs--aa62a";
export var cdsVisuallyHidden = "Aside-module--cds--visually-hidden--53185";
export var hideFeedback = "Aside-module--hide-feedback--e630d";
export var showFeedback = "Aside-module--show-feedback--86ca6";
export var skeleton = "Aside-module--skeleton--95b53";