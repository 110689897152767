// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Header-module--cds--assistive-text--2d64a";
export var cdsLayoutConstraintDensity__defaultCondensed = "Header-module--cds--layout-constraint--density__default-condensed--8eb04";
export var cdsLayoutConstraintDensity__defaultNormal = "Header-module--cds--layout-constraint--density__default-normal--647fd";
export var cdsLayoutConstraintDensity__maxCondensed = "Header-module--cds--layout-constraint--density__max-condensed--83abe";
export var cdsLayoutConstraintDensity__maxNormal = "Header-module--cds--layout-constraint--density__max-normal--20dda";
export var cdsLayoutConstraintDensity__minCondensed = "Header-module--cds--layout-constraint--density__min-condensed--edd96";
export var cdsLayoutConstraintDensity__minNormal = "Header-module--cds--layout-constraint--density__min-normal--9d9ec";
export var cdsLayoutConstraintSize__default2xl = "Header-module--cds--layout-constraint--size__default-2xl--5dcef";
export var cdsLayoutConstraintSize__defaultLg = "Header-module--cds--layout-constraint--size__default-lg--46feb";
export var cdsLayoutConstraintSize__defaultMd = "Header-module--cds--layout-constraint--size__default-md--e776c";
export var cdsLayoutConstraintSize__defaultSm = "Header-module--cds--layout-constraint--size__default-sm--63151";
export var cdsLayoutConstraintSize__defaultXl = "Header-module--cds--layout-constraint--size__default-xl--da2d0";
export var cdsLayoutConstraintSize__defaultXs = "Header-module--cds--layout-constraint--size__default-xs--053da";
export var cdsLayoutConstraintSize__max2xl = "Header-module--cds--layout-constraint--size__max-2xl--a0a5e";
export var cdsLayoutConstraintSize__maxLg = "Header-module--cds--layout-constraint--size__max-lg--1af4d";
export var cdsLayoutConstraintSize__maxMd = "Header-module--cds--layout-constraint--size__max-md--559b3";
export var cdsLayoutConstraintSize__maxSm = "Header-module--cds--layout-constraint--size__max-sm--705a4";
export var cdsLayoutConstraintSize__maxXl = "Header-module--cds--layout-constraint--size__max-xl--ad17b";
export var cdsLayoutConstraintSize__maxXs = "Header-module--cds--layout-constraint--size__max-xs--e7096";
export var cdsLayoutConstraintSize__min2xl = "Header-module--cds--layout-constraint--size__min-2xl--8307e";
export var cdsLayoutConstraintSize__minLg = "Header-module--cds--layout-constraint--size__min-lg--74962";
export var cdsLayoutConstraintSize__minMd = "Header-module--cds--layout-constraint--size__min-md--a6cd9";
export var cdsLayoutConstraintSize__minSm = "Header-module--cds--layout-constraint--size__min-sm--71ad1";
export var cdsLayoutConstraintSize__minXl = "Header-module--cds--layout-constraint--size__min-xl--096f8";
export var cdsLayoutConstraintSize__minXs = "Header-module--cds--layout-constraint--size__min-xs--c2753";
export var cdsLayoutDensityCondensed = "Header-module--cds--layout--density-condensed--dd2c4";
export var cdsLayoutDensityNormal = "Header-module--cds--layout--density-normal--e0f9f";
export var cdsLayoutSize2xl = "Header-module--cds--layout--size-2xl--abc96";
export var cdsLayoutSizeLg = "Header-module--cds--layout--size-lg--9c1ac";
export var cdsLayoutSizeMd = "Header-module--cds--layout--size-md--5a8fa";
export var cdsLayoutSizeSm = "Header-module--cds--layout--size-sm--db98d";
export var cdsLayoutSizeXl = "Header-module--cds--layout--size-xl--ec805";
export var cdsLayoutSizeXs = "Header-module--cds--layout--size-xs--bbdc0";
export var cdsVisuallyHidden = "Header-module--cds--visually-hidden--8b0c1";
export var header = "Header-module--header--d5120";
export var headerButton = "Header-module--header-button--ebb2a";
export var headerName = "Header-module--headerName--3b600";
export var headerNameWithHeaderNav = "Header-module--headerNameWithHeaderNav--981b5";
export var headerWithHeaderNav = "Header-module--headerWithHeaderNav--87c30";
export var hideFeedback = "Header-module--hide-feedback--d2fce";
export var searchIsOpenOnBar = "Header-module--searchIsOpenOnBar--396d4";
export var searchIsOpenOnLink = "Header-module--searchIsOpenOnLink--2cd47";
export var showFeedback = "Header-module--show-feedback--b8eb6";
export var skeleton = "Header-module--skeleton--cc91a";
export var skipToContent = "Header-module--skip-to-content--f3642";
export var switcherButton = "Header-module--switcher-button--3ae5b";
export var switcherButtonOpen = "Header-module--switcher-button--open--b41bf";