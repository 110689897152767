// extracted by mini-css-extract-plugin
export var border = "HeaderNavItem-module--border--3ebfe";
export var cdsAssistiveText = "HeaderNavItem-module--cds--assistive-text--431eb";
export var cdsLayoutConstraintDensity__defaultCondensed = "HeaderNavItem-module--cds--layout-constraint--density__default-condensed--44e07";
export var cdsLayoutConstraintDensity__defaultNormal = "HeaderNavItem-module--cds--layout-constraint--density__default-normal--d5b5f";
export var cdsLayoutConstraintDensity__maxCondensed = "HeaderNavItem-module--cds--layout-constraint--density__max-condensed--ea5b6";
export var cdsLayoutConstraintDensity__maxNormal = "HeaderNavItem-module--cds--layout-constraint--density__max-normal--3fdbd";
export var cdsLayoutConstraintDensity__minCondensed = "HeaderNavItem-module--cds--layout-constraint--density__min-condensed--083cb";
export var cdsLayoutConstraintDensity__minNormal = "HeaderNavItem-module--cds--layout-constraint--density__min-normal--d7efc";
export var cdsLayoutConstraintSize__default2xl = "HeaderNavItem-module--cds--layout-constraint--size__default-2xl--6a1ab";
export var cdsLayoutConstraintSize__defaultLg = "HeaderNavItem-module--cds--layout-constraint--size__default-lg--2a47e";
export var cdsLayoutConstraintSize__defaultMd = "HeaderNavItem-module--cds--layout-constraint--size__default-md--d175b";
export var cdsLayoutConstraintSize__defaultSm = "HeaderNavItem-module--cds--layout-constraint--size__default-sm--9b1aa";
export var cdsLayoutConstraintSize__defaultXl = "HeaderNavItem-module--cds--layout-constraint--size__default-xl--a1758";
export var cdsLayoutConstraintSize__defaultXs = "HeaderNavItem-module--cds--layout-constraint--size__default-xs--4d326";
export var cdsLayoutConstraintSize__max2xl = "HeaderNavItem-module--cds--layout-constraint--size__max-2xl--50d56";
export var cdsLayoutConstraintSize__maxLg = "HeaderNavItem-module--cds--layout-constraint--size__max-lg--4f45c";
export var cdsLayoutConstraintSize__maxMd = "HeaderNavItem-module--cds--layout-constraint--size__max-md--51b5f";
export var cdsLayoutConstraintSize__maxSm = "HeaderNavItem-module--cds--layout-constraint--size__max-sm--35157";
export var cdsLayoutConstraintSize__maxXl = "HeaderNavItem-module--cds--layout-constraint--size__max-xl--497b5";
export var cdsLayoutConstraintSize__maxXs = "HeaderNavItem-module--cds--layout-constraint--size__max-xs--1eed1";
export var cdsLayoutConstraintSize__min2xl = "HeaderNavItem-module--cds--layout-constraint--size__min-2xl--1a48e";
export var cdsLayoutConstraintSize__minLg = "HeaderNavItem-module--cds--layout-constraint--size__min-lg--d5c15";
export var cdsLayoutConstraintSize__minMd = "HeaderNavItem-module--cds--layout-constraint--size__min-md--6a963";
export var cdsLayoutConstraintSize__minSm = "HeaderNavItem-module--cds--layout-constraint--size__min-sm--23e98";
export var cdsLayoutConstraintSize__minXl = "HeaderNavItem-module--cds--layout-constraint--size__min-xl--22f4e";
export var cdsLayoutConstraintSize__minXs = "HeaderNavItem-module--cds--layout-constraint--size__min-xs--5ed94";
export var cdsLayoutDensityCondensed = "HeaderNavItem-module--cds--layout--density-condensed--134f5";
export var cdsLayoutDensityNormal = "HeaderNavItem-module--cds--layout--density-normal--b62b1";
export var cdsLayoutSize2xl = "HeaderNavItem-module--cds--layout--size-2xl--182ef";
export var cdsLayoutSizeLg = "HeaderNavItem-module--cds--layout--size-lg--cf08e";
export var cdsLayoutSizeMd = "HeaderNavItem-module--cds--layout--size-md--9f2f4";
export var cdsLayoutSizeSm = "HeaderNavItem-module--cds--layout--size-sm--d8d83";
export var cdsLayoutSizeXl = "HeaderNavItem-module--cds--layout--size-xl--a8946";
export var cdsLayoutSizeXs = "HeaderNavItem-module--cds--layout--size-xs--a7459";
export var cdsVisuallyHidden = "HeaderNavItem-module--cds--visually-hidden--cd946";
export var currentPage = "HeaderNavItem-module--currentPage--39eda";
export var currentPageAccordion = "HeaderNavItem-module--currentPageAccordion--5219c";
export var hideFeedback = "HeaderNavItem-module--hide-feedback--80738";
export var menuItem = "HeaderNavItem-module--menuItem--e91b0";
export var showFeedback = "HeaderNavItem-module--show-feedback--694db";
export var skeleton = "HeaderNavItem-module--skeleton--4d12b";