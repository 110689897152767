// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Link-module--cds--assistive-text--5f625";
export var cdsLayoutConstraintDensity__defaultCondensed = "Link-module--cds--layout-constraint--density__default-condensed--4fe77";
export var cdsLayoutConstraintDensity__defaultNormal = "Link-module--cds--layout-constraint--density__default-normal--6f56e";
export var cdsLayoutConstraintDensity__maxCondensed = "Link-module--cds--layout-constraint--density__max-condensed--2ea28";
export var cdsLayoutConstraintDensity__maxNormal = "Link-module--cds--layout-constraint--density__max-normal--8280a";
export var cdsLayoutConstraintDensity__minCondensed = "Link-module--cds--layout-constraint--density__min-condensed--a2cfa";
export var cdsLayoutConstraintDensity__minNormal = "Link-module--cds--layout-constraint--density__min-normal--502c9";
export var cdsLayoutConstraintSize__default2xl = "Link-module--cds--layout-constraint--size__default-2xl--d2069";
export var cdsLayoutConstraintSize__defaultLg = "Link-module--cds--layout-constraint--size__default-lg--3c971";
export var cdsLayoutConstraintSize__defaultMd = "Link-module--cds--layout-constraint--size__default-md--dda9e";
export var cdsLayoutConstraintSize__defaultSm = "Link-module--cds--layout-constraint--size__default-sm--d0d58";
export var cdsLayoutConstraintSize__defaultXl = "Link-module--cds--layout-constraint--size__default-xl--4c384";
export var cdsLayoutConstraintSize__defaultXs = "Link-module--cds--layout-constraint--size__default-xs--59fd4";
export var cdsLayoutConstraintSize__max2xl = "Link-module--cds--layout-constraint--size__max-2xl--f60ad";
export var cdsLayoutConstraintSize__maxLg = "Link-module--cds--layout-constraint--size__max-lg--27caf";
export var cdsLayoutConstraintSize__maxMd = "Link-module--cds--layout-constraint--size__max-md--a7f8b";
export var cdsLayoutConstraintSize__maxSm = "Link-module--cds--layout-constraint--size__max-sm--760ed";
export var cdsLayoutConstraintSize__maxXl = "Link-module--cds--layout-constraint--size__max-xl--07891";
export var cdsLayoutConstraintSize__maxXs = "Link-module--cds--layout-constraint--size__max-xs--33214";
export var cdsLayoutConstraintSize__min2xl = "Link-module--cds--layout-constraint--size__min-2xl--40607";
export var cdsLayoutConstraintSize__minLg = "Link-module--cds--layout-constraint--size__min-lg--b7f28";
export var cdsLayoutConstraintSize__minMd = "Link-module--cds--layout-constraint--size__min-md--1ea23";
export var cdsLayoutConstraintSize__minSm = "Link-module--cds--layout-constraint--size__min-sm--3fbe5";
export var cdsLayoutConstraintSize__minXl = "Link-module--cds--layout-constraint--size__min-xl--bcb7a";
export var cdsLayoutConstraintSize__minXs = "Link-module--cds--layout-constraint--size__min-xs--abe23";
export var cdsLayoutDensityCondensed = "Link-module--cds--layout--density-condensed--66b0c";
export var cdsLayoutDensityNormal = "Link-module--cds--layout--density-normal--c67b3";
export var cdsLayoutSize2xl = "Link-module--cds--layout--size-2xl--2540d";
export var cdsLayoutSizeLg = "Link-module--cds--layout--size-lg--8c6e8";
export var cdsLayoutSizeMd = "Link-module--cds--layout--size-md--67117";
export var cdsLayoutSizeSm = "Link-module--cds--layout--size-sm--4c0a6";
export var cdsLayoutSizeXl = "Link-module--cds--layout--size-xl--6eb7c";
export var cdsLayoutSizeXs = "Link-module--cds--layout--size-xs--8d35b";
export var cdsVisuallyHidden = "Link-module--cds--visually-hidden--388f6";
export var hideFeedback = "Link-module--hide-feedback--0859f";
export var link = "Link-module--link--4bd2c";
export var showFeedback = "Link-module--show-feedback--48702";
export var skeleton = "Link-module--skeleton--00c21";