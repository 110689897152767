// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Video-module--cds--assistive-text--483be";
export var cdsLayoutConstraintDensity__defaultCondensed = "Video-module--cds--layout-constraint--density__default-condensed--80d98";
export var cdsLayoutConstraintDensity__defaultNormal = "Video-module--cds--layout-constraint--density__default-normal--59e71";
export var cdsLayoutConstraintDensity__maxCondensed = "Video-module--cds--layout-constraint--density__max-condensed--ecd83";
export var cdsLayoutConstraintDensity__maxNormal = "Video-module--cds--layout-constraint--density__max-normal--cf383";
export var cdsLayoutConstraintDensity__minCondensed = "Video-module--cds--layout-constraint--density__min-condensed--523dd";
export var cdsLayoutConstraintDensity__minNormal = "Video-module--cds--layout-constraint--density__min-normal--f1b49";
export var cdsLayoutConstraintSize__default2xl = "Video-module--cds--layout-constraint--size__default-2xl--7882d";
export var cdsLayoutConstraintSize__defaultLg = "Video-module--cds--layout-constraint--size__default-lg--6de1b";
export var cdsLayoutConstraintSize__defaultMd = "Video-module--cds--layout-constraint--size__default-md--2eb5f";
export var cdsLayoutConstraintSize__defaultSm = "Video-module--cds--layout-constraint--size__default-sm--3d410";
export var cdsLayoutConstraintSize__defaultXl = "Video-module--cds--layout-constraint--size__default-xl--79ec3";
export var cdsLayoutConstraintSize__defaultXs = "Video-module--cds--layout-constraint--size__default-xs--3b042";
export var cdsLayoutConstraintSize__max2xl = "Video-module--cds--layout-constraint--size__max-2xl--86c69";
export var cdsLayoutConstraintSize__maxLg = "Video-module--cds--layout-constraint--size__max-lg--6118a";
export var cdsLayoutConstraintSize__maxMd = "Video-module--cds--layout-constraint--size__max-md--475c9";
export var cdsLayoutConstraintSize__maxSm = "Video-module--cds--layout-constraint--size__max-sm--eef7c";
export var cdsLayoutConstraintSize__maxXl = "Video-module--cds--layout-constraint--size__max-xl--6be92";
export var cdsLayoutConstraintSize__maxXs = "Video-module--cds--layout-constraint--size__max-xs--a7d04";
export var cdsLayoutConstraintSize__min2xl = "Video-module--cds--layout-constraint--size__min-2xl--1aa54";
export var cdsLayoutConstraintSize__minLg = "Video-module--cds--layout-constraint--size__min-lg--ed2b4";
export var cdsLayoutConstraintSize__minMd = "Video-module--cds--layout-constraint--size__min-md--e7d9c";
export var cdsLayoutConstraintSize__minSm = "Video-module--cds--layout-constraint--size__min-sm--4a9ad";
export var cdsLayoutConstraintSize__minXl = "Video-module--cds--layout-constraint--size__min-xl--308a0";
export var cdsLayoutConstraintSize__minXs = "Video-module--cds--layout-constraint--size__min-xs--d2eeb";
export var cdsLayoutDensityCondensed = "Video-module--cds--layout--density-condensed--203b1";
export var cdsLayoutDensityNormal = "Video-module--cds--layout--density-normal--23ed7";
export var cdsLayoutSize2xl = "Video-module--cds--layout--size-2xl--4d1a6";
export var cdsLayoutSizeLg = "Video-module--cds--layout--size-lg--d63a7";
export var cdsLayoutSizeMd = "Video-module--cds--layout--size-md--06aad";
export var cdsLayoutSizeSm = "Video-module--cds--layout--size-sm--17cb3";
export var cdsLayoutSizeXl = "Video-module--cds--layout--size-xl--bcbc8";
export var cdsLayoutSizeXs = "Video-module--cds--layout--size-xs--79e2c";
export var cdsVisuallyHidden = "Video-module--cds--visually-hidden--7ad50";
export var hideFeedback = "Video-module--hide-feedback--ca8da";
export var showFeedback = "Video-module--show-feedback--f9621";
export var skeleton = "Video-module--skeleton--e66ff";
export var video = "Video-module--video--483a5";
export var videoButton = "Video-module--video-button--982ec";
export var videoContainer = "Video-module--video-container--b0cda";
export var videoIsPlaying = "Video-module--video--is-playing--3bd64";
export var vimeo = "Video-module--vimeo--febb9";