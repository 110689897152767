// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Comment-module--cds--assistive-text--b6045";
export var cdsLayoutConstraintDensity__defaultCondensed = "Comment-module--cds--layout-constraint--density__default-condensed--b3173";
export var cdsLayoutConstraintDensity__defaultNormal = "Comment-module--cds--layout-constraint--density__default-normal--20c65";
export var cdsLayoutConstraintDensity__maxCondensed = "Comment-module--cds--layout-constraint--density__max-condensed--a4f8d";
export var cdsLayoutConstraintDensity__maxNormal = "Comment-module--cds--layout-constraint--density__max-normal--08113";
export var cdsLayoutConstraintDensity__minCondensed = "Comment-module--cds--layout-constraint--density__min-condensed--2730e";
export var cdsLayoutConstraintDensity__minNormal = "Comment-module--cds--layout-constraint--density__min-normal--21558";
export var cdsLayoutConstraintSize__default2xl = "Comment-module--cds--layout-constraint--size__default-2xl--c5d95";
export var cdsLayoutConstraintSize__defaultLg = "Comment-module--cds--layout-constraint--size__default-lg--6a8f0";
export var cdsLayoutConstraintSize__defaultMd = "Comment-module--cds--layout-constraint--size__default-md--880de";
export var cdsLayoutConstraintSize__defaultSm = "Comment-module--cds--layout-constraint--size__default-sm--b3865";
export var cdsLayoutConstraintSize__defaultXl = "Comment-module--cds--layout-constraint--size__default-xl--faa97";
export var cdsLayoutConstraintSize__defaultXs = "Comment-module--cds--layout-constraint--size__default-xs--058c0";
export var cdsLayoutConstraintSize__max2xl = "Comment-module--cds--layout-constraint--size__max-2xl--a025c";
export var cdsLayoutConstraintSize__maxLg = "Comment-module--cds--layout-constraint--size__max-lg--c1a37";
export var cdsLayoutConstraintSize__maxMd = "Comment-module--cds--layout-constraint--size__max-md--f1bf1";
export var cdsLayoutConstraintSize__maxSm = "Comment-module--cds--layout-constraint--size__max-sm--4bebf";
export var cdsLayoutConstraintSize__maxXl = "Comment-module--cds--layout-constraint--size__max-xl--c40ca";
export var cdsLayoutConstraintSize__maxXs = "Comment-module--cds--layout-constraint--size__max-xs--d2694";
export var cdsLayoutConstraintSize__min2xl = "Comment-module--cds--layout-constraint--size__min-2xl--3b22a";
export var cdsLayoutConstraintSize__minLg = "Comment-module--cds--layout-constraint--size__min-lg--6555e";
export var cdsLayoutConstraintSize__minMd = "Comment-module--cds--layout-constraint--size__min-md--853ae";
export var cdsLayoutConstraintSize__minSm = "Comment-module--cds--layout-constraint--size__min-sm--664c9";
export var cdsLayoutConstraintSize__minXl = "Comment-module--cds--layout-constraint--size__min-xl--0056e";
export var cdsLayoutConstraintSize__minXs = "Comment-module--cds--layout-constraint--size__min-xs--6d8c7";
export var cdsLayoutDensityCondensed = "Comment-module--cds--layout--density-condensed--d1306";
export var cdsLayoutDensityNormal = "Comment-module--cds--layout--density-normal--f64dd";
export var cdsLayoutSize2xl = "Comment-module--cds--layout--size-2xl--a2cea";
export var cdsLayoutSizeLg = "Comment-module--cds--layout--size-lg--5f338";
export var cdsLayoutSizeMd = "Comment-module--cds--layout--size-md--6adc0";
export var cdsLayoutSizeSm = "Comment-module--cds--layout--size-sm--3cf29";
export var cdsLayoutSizeXl = "Comment-module--cds--layout--size-xl--56ee9";
export var cdsLayoutSizeXs = "Comment-module--cds--layout--size-xs--da44f";
export var cdsVisuallyHidden = "Comment-module--cds--visually-hidden--387c3";
export var container = "Comment-module--container--abe49";
export var fadeout = "Comment-module--fadeout--0ed65";
export var focused = "Comment-module--focused--c91a4";
export var hideFeedback = "Comment-module--hide-feedback--04568";
export var showFeedback = "Comment-module--show-feedback--db1fd";
export var skeleton = "Comment-module--skeleton--e9389";
export var textarea = "Comment-module--textarea--34b4c";