// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "PageDescription-module--cds--assistive-text--5b1e7";
export var cdsLayoutConstraintDensity__defaultCondensed = "PageDescription-module--cds--layout-constraint--density__default-condensed--3877c";
export var cdsLayoutConstraintDensity__defaultNormal = "PageDescription-module--cds--layout-constraint--density__default-normal--880cc";
export var cdsLayoutConstraintDensity__maxCondensed = "PageDescription-module--cds--layout-constraint--density__max-condensed--83bbb";
export var cdsLayoutConstraintDensity__maxNormal = "PageDescription-module--cds--layout-constraint--density__max-normal--61c60";
export var cdsLayoutConstraintDensity__minCondensed = "PageDescription-module--cds--layout-constraint--density__min-condensed--5f134";
export var cdsLayoutConstraintDensity__minNormal = "PageDescription-module--cds--layout-constraint--density__min-normal--40c1a";
export var cdsLayoutConstraintSize__default2xl = "PageDescription-module--cds--layout-constraint--size__default-2xl--5f1ea";
export var cdsLayoutConstraintSize__defaultLg = "PageDescription-module--cds--layout-constraint--size__default-lg--e5f05";
export var cdsLayoutConstraintSize__defaultMd = "PageDescription-module--cds--layout-constraint--size__default-md--be3b1";
export var cdsLayoutConstraintSize__defaultSm = "PageDescription-module--cds--layout-constraint--size__default-sm--19d3a";
export var cdsLayoutConstraintSize__defaultXl = "PageDescription-module--cds--layout-constraint--size__default-xl--fb754";
export var cdsLayoutConstraintSize__defaultXs = "PageDescription-module--cds--layout-constraint--size__default-xs--8baf3";
export var cdsLayoutConstraintSize__max2xl = "PageDescription-module--cds--layout-constraint--size__max-2xl--93314";
export var cdsLayoutConstraintSize__maxLg = "PageDescription-module--cds--layout-constraint--size__max-lg--680c0";
export var cdsLayoutConstraintSize__maxMd = "PageDescription-module--cds--layout-constraint--size__max-md--21f78";
export var cdsLayoutConstraintSize__maxSm = "PageDescription-module--cds--layout-constraint--size__max-sm--ebdf7";
export var cdsLayoutConstraintSize__maxXl = "PageDescription-module--cds--layout-constraint--size__max-xl--f837e";
export var cdsLayoutConstraintSize__maxXs = "PageDescription-module--cds--layout-constraint--size__max-xs--5cf0f";
export var cdsLayoutConstraintSize__min2xl = "PageDescription-module--cds--layout-constraint--size__min-2xl--f704d";
export var cdsLayoutConstraintSize__minLg = "PageDescription-module--cds--layout-constraint--size__min-lg--ef9e4";
export var cdsLayoutConstraintSize__minMd = "PageDescription-module--cds--layout-constraint--size__min-md--da09d";
export var cdsLayoutConstraintSize__minSm = "PageDescription-module--cds--layout-constraint--size__min-sm--e8349";
export var cdsLayoutConstraintSize__minXl = "PageDescription-module--cds--layout-constraint--size__min-xl--7a223";
export var cdsLayoutConstraintSize__minXs = "PageDescription-module--cds--layout-constraint--size__min-xs--cc2e1";
export var cdsLayoutDensityCondensed = "PageDescription-module--cds--layout--density-condensed--53970";
export var cdsLayoutDensityNormal = "PageDescription-module--cds--layout--density-normal--a6cef";
export var cdsLayoutSize2xl = "PageDescription-module--cds--layout--size-2xl--655bc";
export var cdsLayoutSizeLg = "PageDescription-module--cds--layout--size-lg--bf1e1";
export var cdsLayoutSizeMd = "PageDescription-module--cds--layout--size-md--53cf1";
export var cdsLayoutSizeSm = "PageDescription-module--cds--layout--size-sm--d711a";
export var cdsLayoutSizeXl = "PageDescription-module--cds--layout--size-xl--50f85";
export var cdsLayoutSizeXs = "PageDescription-module--cds--layout--size-xs--7cb74";
export var cdsVisuallyHidden = "PageDescription-module--cds--visually-hidden--0d8dc";
export var hideFeedback = "PageDescription-module--hide-feedback--e2a8d";
export var pageDescription = "PageDescription-module--page-description--45135";
export var showFeedback = "PageDescription-module--show-feedback--0b49d";
export var skeleton = "PageDescription-module--skeleton--39fd6";