// extracted by mini-css-extract-plugin
export var card = "MiniCard-module--card--d428b";
export var cdsAssistiveText = "MiniCard-module--cds--assistive-text--9b748";
export var cdsLayoutConstraintDensity__defaultCondensed = "MiniCard-module--cds--layout-constraint--density__default-condensed--47cd5";
export var cdsLayoutConstraintDensity__defaultNormal = "MiniCard-module--cds--layout-constraint--density__default-normal--a5093";
export var cdsLayoutConstraintDensity__maxCondensed = "MiniCard-module--cds--layout-constraint--density__max-condensed--fe122";
export var cdsLayoutConstraintDensity__maxNormal = "MiniCard-module--cds--layout-constraint--density__max-normal--8ee1c";
export var cdsLayoutConstraintDensity__minCondensed = "MiniCard-module--cds--layout-constraint--density__min-condensed--1a882";
export var cdsLayoutConstraintDensity__minNormal = "MiniCard-module--cds--layout-constraint--density__min-normal--1e6c3";
export var cdsLayoutConstraintSize__default2xl = "MiniCard-module--cds--layout-constraint--size__default-2xl--08583";
export var cdsLayoutConstraintSize__defaultLg = "MiniCard-module--cds--layout-constraint--size__default-lg--47861";
export var cdsLayoutConstraintSize__defaultMd = "MiniCard-module--cds--layout-constraint--size__default-md--577b5";
export var cdsLayoutConstraintSize__defaultSm = "MiniCard-module--cds--layout-constraint--size__default-sm--67c30";
export var cdsLayoutConstraintSize__defaultXl = "MiniCard-module--cds--layout-constraint--size__default-xl--f51bc";
export var cdsLayoutConstraintSize__defaultXs = "MiniCard-module--cds--layout-constraint--size__default-xs--d91f8";
export var cdsLayoutConstraintSize__max2xl = "MiniCard-module--cds--layout-constraint--size__max-2xl--56564";
export var cdsLayoutConstraintSize__maxLg = "MiniCard-module--cds--layout-constraint--size__max-lg--d6ba7";
export var cdsLayoutConstraintSize__maxMd = "MiniCard-module--cds--layout-constraint--size__max-md--efc7c";
export var cdsLayoutConstraintSize__maxSm = "MiniCard-module--cds--layout-constraint--size__max-sm--f8cb0";
export var cdsLayoutConstraintSize__maxXl = "MiniCard-module--cds--layout-constraint--size__max-xl--3b28c";
export var cdsLayoutConstraintSize__maxXs = "MiniCard-module--cds--layout-constraint--size__max-xs--9d606";
export var cdsLayoutConstraintSize__min2xl = "MiniCard-module--cds--layout-constraint--size__min-2xl--87b7e";
export var cdsLayoutConstraintSize__minLg = "MiniCard-module--cds--layout-constraint--size__min-lg--1c11c";
export var cdsLayoutConstraintSize__minMd = "MiniCard-module--cds--layout-constraint--size__min-md--bd5ba";
export var cdsLayoutConstraintSize__minSm = "MiniCard-module--cds--layout-constraint--size__min-sm--1158f";
export var cdsLayoutConstraintSize__minXl = "MiniCard-module--cds--layout-constraint--size__min-xl--08aac";
export var cdsLayoutConstraintSize__minXs = "MiniCard-module--cds--layout-constraint--size__min-xs--3639c";
export var cdsLayoutDensityCondensed = "MiniCard-module--cds--layout--density-condensed--1b1fc";
export var cdsLayoutDensityNormal = "MiniCard-module--cds--layout--density-normal--fcbcc";
export var cdsLayoutSize2xl = "MiniCard-module--cds--layout--size-2xl--ed0b8";
export var cdsLayoutSizeLg = "MiniCard-module--cds--layout--size-lg--d5dd8";
export var cdsLayoutSizeMd = "MiniCard-module--cds--layout--size-md--ec5ef";
export var cdsLayoutSizeSm = "MiniCard-module--cds--layout--size-sm--d7b11";
export var cdsLayoutSizeXl = "MiniCard-module--cds--layout--size-xl--1efa6";
export var cdsLayoutSizeXs = "MiniCard-module--cds--layout--size-xs--20fac";
export var cdsVisuallyHidden = "MiniCard-module--cds--visually-hidden--7ea74";
export var hideFeedback = "MiniCard-module--hide-feedback--e4cb5";
export var icon = "MiniCard-module--icon--719eb";
export var image = "MiniCard-module--image--ea7e9";
export var showFeedback = "MiniCard-module--show-feedback--2ab1a";
export var skeleton = "MiniCard-module--skeleton--3b456";
export var title = "MiniCard-module--title--1e7a7";
export var wrapper = "MiniCard-module--wrapper--bf409";