// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Tabs-module--cds--assistive-text--dbe80";
export var cdsLayoutConstraintDensity__defaultCondensed = "Tabs-module--cds--layout-constraint--density__default-condensed--a7606";
export var cdsLayoutConstraintDensity__defaultNormal = "Tabs-module--cds--layout-constraint--density__default-normal--8610b";
export var cdsLayoutConstraintDensity__maxCondensed = "Tabs-module--cds--layout-constraint--density__max-condensed--7edbc";
export var cdsLayoutConstraintDensity__maxNormal = "Tabs-module--cds--layout-constraint--density__max-normal--4659d";
export var cdsLayoutConstraintDensity__minCondensed = "Tabs-module--cds--layout-constraint--density__min-condensed--9b3fb";
export var cdsLayoutConstraintDensity__minNormal = "Tabs-module--cds--layout-constraint--density__min-normal--07256";
export var cdsLayoutConstraintSize__default2xl = "Tabs-module--cds--layout-constraint--size__default-2xl--504c7";
export var cdsLayoutConstraintSize__defaultLg = "Tabs-module--cds--layout-constraint--size__default-lg--2aba4";
export var cdsLayoutConstraintSize__defaultMd = "Tabs-module--cds--layout-constraint--size__default-md--d0052";
export var cdsLayoutConstraintSize__defaultSm = "Tabs-module--cds--layout-constraint--size__default-sm--850f6";
export var cdsLayoutConstraintSize__defaultXl = "Tabs-module--cds--layout-constraint--size__default-xl--c03ec";
export var cdsLayoutConstraintSize__defaultXs = "Tabs-module--cds--layout-constraint--size__default-xs--6d49e";
export var cdsLayoutConstraintSize__max2xl = "Tabs-module--cds--layout-constraint--size__max-2xl--63533";
export var cdsLayoutConstraintSize__maxLg = "Tabs-module--cds--layout-constraint--size__max-lg--a47cd";
export var cdsLayoutConstraintSize__maxMd = "Tabs-module--cds--layout-constraint--size__max-md--8519f";
export var cdsLayoutConstraintSize__maxSm = "Tabs-module--cds--layout-constraint--size__max-sm--69494";
export var cdsLayoutConstraintSize__maxXl = "Tabs-module--cds--layout-constraint--size__max-xl--dbf2d";
export var cdsLayoutConstraintSize__maxXs = "Tabs-module--cds--layout-constraint--size__max-xs--225d8";
export var cdsLayoutConstraintSize__min2xl = "Tabs-module--cds--layout-constraint--size__min-2xl--3953d";
export var cdsLayoutConstraintSize__minLg = "Tabs-module--cds--layout-constraint--size__min-lg--7d2c4";
export var cdsLayoutConstraintSize__minMd = "Tabs-module--cds--layout-constraint--size__min-md--e8ad5";
export var cdsLayoutConstraintSize__minSm = "Tabs-module--cds--layout-constraint--size__min-sm--a29de";
export var cdsLayoutConstraintSize__minXl = "Tabs-module--cds--layout-constraint--size__min-xl--25664";
export var cdsLayoutConstraintSize__minXs = "Tabs-module--cds--layout-constraint--size__min-xs--5bb45";
export var cdsLayoutDensityCondensed = "Tabs-module--cds--layout--density-condensed--b1773";
export var cdsLayoutDensityNormal = "Tabs-module--cds--layout--density-normal--41365";
export var cdsLayoutSize2xl = "Tabs-module--cds--layout--size-2xl--ac7a8";
export var cdsLayoutSizeLg = "Tabs-module--cds--layout--size-lg--fd211";
export var cdsLayoutSizeMd = "Tabs-module--cds--layout--size-md--f6ad1";
export var cdsLayoutSizeSm = "Tabs-module--cds--layout--size-sm--0b816";
export var cdsLayoutSizeXl = "Tabs-module--cds--layout--size-xl--2dc11";
export var cdsLayoutSizeXs = "Tabs-module--cds--layout--size-xs--b70a2";
export var cdsVisuallyHidden = "Tabs-module--cds--visually-hidden--383c8";
export var dropdownWrapper = "Tabs-module--dropdownWrapper--0c721";
export var hideFeedback = "Tabs-module--hide-feedback--a3fb7";
export var panel = "Tabs-module--panel--2a0af";
export var showFeedback = "Tabs-module--show-feedback--610c1";
export var skeleton = "Tabs-module--skeleton--9fb6a";
export var tab = "Tabs-module--tab--59c2a";
export var tabList = "Tabs-module--tab-list--f46b0";