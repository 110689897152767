// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Main-module--cds--assistive-text--681af";
export var cdsLayoutConstraintDensity__defaultCondensed = "Main-module--cds--layout-constraint--density__default-condensed--bfe91";
export var cdsLayoutConstraintDensity__defaultNormal = "Main-module--cds--layout-constraint--density__default-normal--49750";
export var cdsLayoutConstraintDensity__maxCondensed = "Main-module--cds--layout-constraint--density__max-condensed--84646";
export var cdsLayoutConstraintDensity__maxNormal = "Main-module--cds--layout-constraint--density__max-normal--659db";
export var cdsLayoutConstraintDensity__minCondensed = "Main-module--cds--layout-constraint--density__min-condensed--5dafd";
export var cdsLayoutConstraintDensity__minNormal = "Main-module--cds--layout-constraint--density__min-normal--31676";
export var cdsLayoutConstraintSize__default2xl = "Main-module--cds--layout-constraint--size__default-2xl--93712";
export var cdsLayoutConstraintSize__defaultLg = "Main-module--cds--layout-constraint--size__default-lg--9b58c";
export var cdsLayoutConstraintSize__defaultMd = "Main-module--cds--layout-constraint--size__default-md--37625";
export var cdsLayoutConstraintSize__defaultSm = "Main-module--cds--layout-constraint--size__default-sm--5a10d";
export var cdsLayoutConstraintSize__defaultXl = "Main-module--cds--layout-constraint--size__default-xl--817b5";
export var cdsLayoutConstraintSize__defaultXs = "Main-module--cds--layout-constraint--size__default-xs--ae8de";
export var cdsLayoutConstraintSize__max2xl = "Main-module--cds--layout-constraint--size__max-2xl--934b8";
export var cdsLayoutConstraintSize__maxLg = "Main-module--cds--layout-constraint--size__max-lg--16a35";
export var cdsLayoutConstraintSize__maxMd = "Main-module--cds--layout-constraint--size__max-md--5a25e";
export var cdsLayoutConstraintSize__maxSm = "Main-module--cds--layout-constraint--size__max-sm--0d8eb";
export var cdsLayoutConstraintSize__maxXl = "Main-module--cds--layout-constraint--size__max-xl--e4c91";
export var cdsLayoutConstraintSize__maxXs = "Main-module--cds--layout-constraint--size__max-xs--76321";
export var cdsLayoutConstraintSize__min2xl = "Main-module--cds--layout-constraint--size__min-2xl--c24cb";
export var cdsLayoutConstraintSize__minLg = "Main-module--cds--layout-constraint--size__min-lg--949a1";
export var cdsLayoutConstraintSize__minMd = "Main-module--cds--layout-constraint--size__min-md--9939c";
export var cdsLayoutConstraintSize__minSm = "Main-module--cds--layout-constraint--size__min-sm--05be2";
export var cdsLayoutConstraintSize__minXl = "Main-module--cds--layout-constraint--size__min-xl--e984c";
export var cdsLayoutConstraintSize__minXs = "Main-module--cds--layout-constraint--size__min-xs--f5b4f";
export var cdsLayoutDensityCondensed = "Main-module--cds--layout--density-condensed--3a88b";
export var cdsLayoutDensityNormal = "Main-module--cds--layout--density-normal--4fbe7";
export var cdsLayoutSize2xl = "Main-module--cds--layout--size-2xl--788c4";
export var cdsLayoutSizeLg = "Main-module--cds--layout--size-lg--8a54b";
export var cdsLayoutSizeMd = "Main-module--cds--layout--size-md--7bfe1";
export var cdsLayoutSizeSm = "Main-module--cds--layout--size-sm--8d40d";
export var cdsLayoutSizeXl = "Main-module--cds--layout--size-xl--ab926";
export var cdsLayoutSizeXs = "Main-module--cds--layout--size-xs--67b13";
export var cdsVisuallyHidden = "Main-module--cds--visually-hidden--93304";
export var hideFeedback = "Main-module--hide-feedback--90072";
export var main = "Main-module--main--3c1fa";
export var padded = "Main-module--padded--2eee7";
export var showFeedback = "Main-module--show-feedback--b8906";
export var skeleton = "Main-module--skeleton--f6323";