// extracted by mini-css-extract-plugin
export var cardGroup = "CardGroup-module--cardGroup--a249b";
export var cdsAssistiveText = "CardGroup-module--cds--assistive-text--9af8b";
export var cdsLayoutConstraintDensity__defaultCondensed = "CardGroup-module--cds--layout-constraint--density__default-condensed--a7cc0";
export var cdsLayoutConstraintDensity__defaultNormal = "CardGroup-module--cds--layout-constraint--density__default-normal--a98f4";
export var cdsLayoutConstraintDensity__maxCondensed = "CardGroup-module--cds--layout-constraint--density__max-condensed--d47b2";
export var cdsLayoutConstraintDensity__maxNormal = "CardGroup-module--cds--layout-constraint--density__max-normal--ba653";
export var cdsLayoutConstraintDensity__minCondensed = "CardGroup-module--cds--layout-constraint--density__min-condensed--5608f";
export var cdsLayoutConstraintDensity__minNormal = "CardGroup-module--cds--layout-constraint--density__min-normal--b815d";
export var cdsLayoutConstraintSize__default2xl = "CardGroup-module--cds--layout-constraint--size__default-2xl--6eb0f";
export var cdsLayoutConstraintSize__defaultLg = "CardGroup-module--cds--layout-constraint--size__default-lg--f56f2";
export var cdsLayoutConstraintSize__defaultMd = "CardGroup-module--cds--layout-constraint--size__default-md--3ea41";
export var cdsLayoutConstraintSize__defaultSm = "CardGroup-module--cds--layout-constraint--size__default-sm--c8f2d";
export var cdsLayoutConstraintSize__defaultXl = "CardGroup-module--cds--layout-constraint--size__default-xl--0dab4";
export var cdsLayoutConstraintSize__defaultXs = "CardGroup-module--cds--layout-constraint--size__default-xs--c72a9";
export var cdsLayoutConstraintSize__max2xl = "CardGroup-module--cds--layout-constraint--size__max-2xl--e8ac4";
export var cdsLayoutConstraintSize__maxLg = "CardGroup-module--cds--layout-constraint--size__max-lg--42c12";
export var cdsLayoutConstraintSize__maxMd = "CardGroup-module--cds--layout-constraint--size__max-md--8aa3e";
export var cdsLayoutConstraintSize__maxSm = "CardGroup-module--cds--layout-constraint--size__max-sm--07d3c";
export var cdsLayoutConstraintSize__maxXl = "CardGroup-module--cds--layout-constraint--size__max-xl--2450b";
export var cdsLayoutConstraintSize__maxXs = "CardGroup-module--cds--layout-constraint--size__max-xs--02c96";
export var cdsLayoutConstraintSize__min2xl = "CardGroup-module--cds--layout-constraint--size__min-2xl--e5a21";
export var cdsLayoutConstraintSize__minLg = "CardGroup-module--cds--layout-constraint--size__min-lg--1060c";
export var cdsLayoutConstraintSize__minMd = "CardGroup-module--cds--layout-constraint--size__min-md--aef7d";
export var cdsLayoutConstraintSize__minSm = "CardGroup-module--cds--layout-constraint--size__min-sm--b0fc7";
export var cdsLayoutConstraintSize__minXl = "CardGroup-module--cds--layout-constraint--size__min-xl--c6f2a";
export var cdsLayoutConstraintSize__minXs = "CardGroup-module--cds--layout-constraint--size__min-xs--25add";
export var cdsLayoutDensityCondensed = "CardGroup-module--cds--layout--density-condensed--6c23f";
export var cdsLayoutDensityNormal = "CardGroup-module--cds--layout--density-normal--75dfb";
export var cdsLayoutSize2xl = "CardGroup-module--cds--layout--size-2xl--3f085";
export var cdsLayoutSizeLg = "CardGroup-module--cds--layout--size-lg--10c19";
export var cdsLayoutSizeMd = "CardGroup-module--cds--layout--size-md--8606e";
export var cdsLayoutSizeSm = "CardGroup-module--cds--layout--size-sm--53340";
export var cdsLayoutSizeXl = "CardGroup-module--cds--layout--size-xl--891aa";
export var cdsLayoutSizeXs = "CardGroup-module--cds--layout--size-xs--17dda";
export var cdsVisuallyHidden = "CardGroup-module--cds--visually-hidden--91f2a";
export var hideFeedback = "CardGroup-module--hide-feedback--eeef4";
export var showFeedback = "CardGroup-module--show-feedback--16ef5";
export var skeleton = "CardGroup-module--skeleton--24c32";