// extracted by mini-css-extract-plugin
export var actionIcon = "SquareCard-module--actionIcon--fc1d2";
export var body = "SquareCard-module--body--b6930";
export var cdsAssistiveText = "SquareCard-module--cds--assistive-text--62f32";
export var cdsLayoutConstraintDensity__defaultCondensed = "SquareCard-module--cds--layout-constraint--density__default-condensed--c6fe2";
export var cdsLayoutConstraintDensity__defaultNormal = "SquareCard-module--cds--layout-constraint--density__default-normal--1cfe2";
export var cdsLayoutConstraintDensity__maxCondensed = "SquareCard-module--cds--layout-constraint--density__max-condensed--14eea";
export var cdsLayoutConstraintDensity__maxNormal = "SquareCard-module--cds--layout-constraint--density__max-normal--6139b";
export var cdsLayoutConstraintDensity__minCondensed = "SquareCard-module--cds--layout-constraint--density__min-condensed--27a48";
export var cdsLayoutConstraintDensity__minNormal = "SquareCard-module--cds--layout-constraint--density__min-normal--04cd0";
export var cdsLayoutConstraintSize__default2xl = "SquareCard-module--cds--layout-constraint--size__default-2xl--89557";
export var cdsLayoutConstraintSize__defaultLg = "SquareCard-module--cds--layout-constraint--size__default-lg--cacb7";
export var cdsLayoutConstraintSize__defaultMd = "SquareCard-module--cds--layout-constraint--size__default-md--5ffa9";
export var cdsLayoutConstraintSize__defaultSm = "SquareCard-module--cds--layout-constraint--size__default-sm--122d8";
export var cdsLayoutConstraintSize__defaultXl = "SquareCard-module--cds--layout-constraint--size__default-xl--4c970";
export var cdsLayoutConstraintSize__defaultXs = "SquareCard-module--cds--layout-constraint--size__default-xs--738f3";
export var cdsLayoutConstraintSize__max2xl = "SquareCard-module--cds--layout-constraint--size__max-2xl--67a51";
export var cdsLayoutConstraintSize__maxLg = "SquareCard-module--cds--layout-constraint--size__max-lg--b9da5";
export var cdsLayoutConstraintSize__maxMd = "SquareCard-module--cds--layout-constraint--size__max-md--be8ba";
export var cdsLayoutConstraintSize__maxSm = "SquareCard-module--cds--layout-constraint--size__max-sm--aabce";
export var cdsLayoutConstraintSize__maxXl = "SquareCard-module--cds--layout-constraint--size__max-xl--5ca44";
export var cdsLayoutConstraintSize__maxXs = "SquareCard-module--cds--layout-constraint--size__max-xs--54636";
export var cdsLayoutConstraintSize__min2xl = "SquareCard-module--cds--layout-constraint--size__min-2xl--9f66c";
export var cdsLayoutConstraintSize__minLg = "SquareCard-module--cds--layout-constraint--size__min-lg--54578";
export var cdsLayoutConstraintSize__minMd = "SquareCard-module--cds--layout-constraint--size__min-md--1baf6";
export var cdsLayoutConstraintSize__minSm = "SquareCard-module--cds--layout-constraint--size__min-sm--b00b9";
export var cdsLayoutConstraintSize__minXl = "SquareCard-module--cds--layout-constraint--size__min-xl--991c1";
export var cdsLayoutConstraintSize__minXs = "SquareCard-module--cds--layout-constraint--size__min-xs--d7091";
export var cdsLayoutDensityCondensed = "SquareCard-module--cds--layout--density-condensed--3c3b2";
export var cdsLayoutDensityNormal = "SquareCard-module--cds--layout--density-normal--b44e4";
export var cdsLayoutSize2xl = "SquareCard-module--cds--layout--size-2xl--d5ea5";
export var cdsLayoutSizeLg = "SquareCard-module--cds--layout--size-lg--cfc13";
export var cdsLayoutSizeMd = "SquareCard-module--cds--layout--size-md--a45d9";
export var cdsLayoutSizeSm = "SquareCard-module--cds--layout--size-sm--ab55a";
export var cdsLayoutSizeXl = "SquareCard-module--cds--layout--size-xl--80867";
export var cdsLayoutSizeXs = "SquareCard-module--cds--layout--size-xs--5d01d";
export var cdsVisuallyHidden = "SquareCard-module--cds--visually-hidden--49344";
export var darkMode = "SquareCard-module--darkMode--60624";
export var disabled = "SquareCard-module--disabled--f1ff4";
export var helperIcon = "SquareCard-module--helperIcon--c46db";
export var helperText = "SquareCard-module--helperText--8287c";
export var hideFeedback = "SquareCard-module--hide-feedback--ffb10";
export var showFeedback = "SquareCard-module--show-feedback--45a0f";
export var skeleton = "SquareCard-module--skeleton--72600";
export var squareCard = "SquareCard-module--squareCard--45b8f";
export var title = "SquareCard-module--title--17f95";
export var titleSmall = "SquareCard-module--titleSmall--4423c";