// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Callout-module--cds--assistive-text--7f2c6";
export var cdsLayoutConstraintDensity__defaultCondensed = "Callout-module--cds--layout-constraint--density__default-condensed--12c14";
export var cdsLayoutConstraintDensity__defaultNormal = "Callout-module--cds--layout-constraint--density__default-normal--8c54f";
export var cdsLayoutConstraintDensity__maxCondensed = "Callout-module--cds--layout-constraint--density__max-condensed--21bfb";
export var cdsLayoutConstraintDensity__maxNormal = "Callout-module--cds--layout-constraint--density__max-normal--d7199";
export var cdsLayoutConstraintDensity__minCondensed = "Callout-module--cds--layout-constraint--density__min-condensed--f6b88";
export var cdsLayoutConstraintDensity__minNormal = "Callout-module--cds--layout-constraint--density__min-normal--c55e9";
export var cdsLayoutConstraintSize__default2xl = "Callout-module--cds--layout-constraint--size__default-2xl--c49c5";
export var cdsLayoutConstraintSize__defaultLg = "Callout-module--cds--layout-constraint--size__default-lg--835a7";
export var cdsLayoutConstraintSize__defaultMd = "Callout-module--cds--layout-constraint--size__default-md--7cf52";
export var cdsLayoutConstraintSize__defaultSm = "Callout-module--cds--layout-constraint--size__default-sm--1578b";
export var cdsLayoutConstraintSize__defaultXl = "Callout-module--cds--layout-constraint--size__default-xl--a9991";
export var cdsLayoutConstraintSize__defaultXs = "Callout-module--cds--layout-constraint--size__default-xs--0e473";
export var cdsLayoutConstraintSize__max2xl = "Callout-module--cds--layout-constraint--size__max-2xl--c9d4a";
export var cdsLayoutConstraintSize__maxLg = "Callout-module--cds--layout-constraint--size__max-lg--4306c";
export var cdsLayoutConstraintSize__maxMd = "Callout-module--cds--layout-constraint--size__max-md--a214e";
export var cdsLayoutConstraintSize__maxSm = "Callout-module--cds--layout-constraint--size__max-sm--f34e1";
export var cdsLayoutConstraintSize__maxXl = "Callout-module--cds--layout-constraint--size__max-xl--84582";
export var cdsLayoutConstraintSize__maxXs = "Callout-module--cds--layout-constraint--size__max-xs--98a5b";
export var cdsLayoutConstraintSize__min2xl = "Callout-module--cds--layout-constraint--size__min-2xl--291cd";
export var cdsLayoutConstraintSize__minLg = "Callout-module--cds--layout-constraint--size__min-lg--69345";
export var cdsLayoutConstraintSize__minMd = "Callout-module--cds--layout-constraint--size__min-md--f9115";
export var cdsLayoutConstraintSize__minSm = "Callout-module--cds--layout-constraint--size__min-sm--9f823";
export var cdsLayoutConstraintSize__minXl = "Callout-module--cds--layout-constraint--size__min-xl--fc428";
export var cdsLayoutConstraintSize__minXs = "Callout-module--cds--layout-constraint--size__min-xs--84e78";
export var cdsLayoutDensityCondensed = "Callout-module--cds--layout--density-condensed--92c3c";
export var cdsLayoutDensityNormal = "Callout-module--cds--layout--density-normal--fac96";
export var cdsLayoutSize2xl = "Callout-module--cds--layout--size-2xl--c731e";
export var cdsLayoutSizeLg = "Callout-module--cds--layout--size-lg--80da5";
export var cdsLayoutSizeMd = "Callout-module--cds--layout--size-md--ff268";
export var cdsLayoutSizeSm = "Callout-module--cds--layout--size-sm--039b6";
export var cdsLayoutSizeXl = "Callout-module--cds--layout--size-xl--de1e4";
export var cdsLayoutSizeXs = "Callout-module--cds--layout--size-xs--e42a0";
export var cdsVisuallyHidden = "Callout-module--cds--visually-hidden--9b2df";
export var firstColumn = "Callout-module--firstColumn--3bb56";
export var grid = "Callout-module--grid--a4b26";
export var hideFeedback = "Callout-module--hide-feedback--2cdc8";
export var row = "Callout-module--row--63836";
export var secondColumn = "Callout-module--secondColumn--b8fdc";
export var showFeedback = "Callout-module--show-feedback--c12e0";
export var skeleton = "Callout-module--skeleton--ff96f";